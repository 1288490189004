import { ReactComponent as TestLogo } from '../../Assests/testLogo.svg'
import { Link } from 'react-router-dom';

export const SiteBody = () =>{
    return(

    <main className="bg-gray-900 h-screen">

        <h1 className="text-4xl font-normal leading-normal mt-0 mb-2 text-white flex justify-center md:justify-start md:pl-6">
            Websites
        </h1>
        <Link to="/plastic-and-metal-comp">
            <div className="grid grid-col-1 md:grid-cols-3 gap-4 md:pl-6">
                <div className="flex flex-col bg-gray-100 rounded-lg p-4 m-2">
                    <div className="h-40 bg-gray-400 rounded-lg">
                        <TestLogo ></TestLogo>
                    </div>
                    <div className="flex flex-col items-start mt-4">
                        <h4 className="text-xl font-semibold">Plastic & Metal Components</h4>
                        <p className="text-sm">Work In Progress</p>
                        {/* <a className="flex justify-center p-2 leading-none rounded font-medium mt-3 bg-green-500 text-xs uppercase" href="#">View</a> */}
                    </div>
                </div>
            </div>
        </Link>
    </main>
    )
}