// import CompanyLogo from './CompanyLogo';
//import { ReactComponent as Logo } from '../../Assests/PandC.svg'
import CompanyLogo from './CompanyLogo';

export const Navigation = () => {
    return(
    <>
      
        <div className='grid grid-cols-2 lg:flex lg:justify-start py-2'>

            <div>
                <div className='pl-2'> 
                    <CompanyLogo fill="#fcd34d" height="100px" width="250px" x='100' y='155' viewBox='140 165 600 300'/> 
                </div>
            </div>
            <div className='hidden lg:text-3xl lg:pr-16 lg:pt-4 lg:inline-block'>Plastic and Metal<br/> Components</div>

            <div className='hidden lg:grid grid-cols-3 gap-8 lg:pr-28'>
                <button className='text-xl'> About</button>
                <button className='text-xl'>Component List</button>
                <button className="my-8 bg-amber-300 text-grey px-2 rounded-md hover:bg-white hover:text-amber-400 border-2 border-amber-300">Get a Qoute</button>
            </div>

            <div className="hidden lg:inline-block lg:text-center lg:pt-4 lg:text-xl text-amber-300">
                (203) 877-2723 <br/> sale@plastic-metal-comp.com
            </div>

            {/* <div className="hidden lg:inline lg:text-xl">About</div>
            <div className="hidden lg:inline lg:text-xl">Component List</div>
            <div className="hidden lg:inline lg:text-xl">Get A Qoute!</div>
            <div className="hidden lg:grid grid-cols-1">
                <div>(203) 887 - 2723</div>
                <div>sales@plastic-metal-comp.com</div>
            </div> */}

            {/* Mobile Menu  */}
            {/* items-center */}  
            <div className="lg:hidden flex items-center pl-24 md:pl-72">
                <button className="outline-none mobile-menu-button">
                    <svg
                        className="w-12 h-12 text-amber-300"
                        x-show="!showMenu"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>

        </div>
        <hr/>
    </>
    )
}