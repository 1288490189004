export const Hero = ({
    title = 'Plastic & Metal Components',
    statement = ''
}) => {
    return(
    <>
        <div className="heroImage h-full">
            <div className="flex justify-center p-20 m:p-36">
                <div className="my-24 bg-white rounded-lg p-2 md:p-8">
                    {/* <h1 className="text-center text-amber-300 text-2xl">{title}</h1> */}
                    <h2 className="text-center text-gray-500 text-2xl md:text-4xl bg-amber-300 p-4"><i>{statement}</i></h2>
                </div>
            </div>
        </div>
    </>
    )
}

// import CompanyLogo from "./CompanyLogo"

// export const Hero = ({
//         title = 'Plastic & Metal Components',
//         statement = ''
//     }) => {
//         return(
//         <>
//             {/* Large */}
//             <div className="lg:grid lg:grid-cols-2 h-full">
//                 <div>
//                     <CompanyLogo fill="#fcd34d" height="100%" width="100%"/>
//                 </div>
//                 <div className="heroRight hidden lg:block">
//                     <div className="flex justify-center my-32 p-8">
//                         <div className="bg-white rounded-lg p-32">
//                             <h1 className="text-center text-amber-300 text-2xl">{title}</h1>
//                             <h2 className="text-center text-gray-500 text-sm">{statement}</h2>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* Small */}
//             <div>

//             </div>
//         </>
//         )
//     }