import {ReactComponent as Logo} from '../../Assests/logo.svg';

export const SiteHeader = () => {
    return(
    <header>
        <nav className="bg-black flex justify-center md:justify-start md:pl-6">
            <Logo style={{ height: 53, width: 156 }}></Logo>
        </nav>
    </header>
    )
}