import {Navigation} from "./Navigation"
import {Hero} from "./Hero"

export const Home = () => {
    return(
    <>
        {/* py-40 */}
        <div>
            <Navigation></Navigation>
            <Hero statement="The Source Where Thousands of Distributers Do Their Shopping"/>
        </div>
        {/* <div className="">
            <Navigation/>
            <Hero 
                title="Your #1 Source for Fasteners"
                statement="Inventory is our Priority"
            />
        </div> */}
    </>
    )
}