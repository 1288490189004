import { SiteHeader } from './SiteHeader'
import { SiteBody } from './SiteBody'

export const Site = () => {
    return(
    <>
        <SiteHeader/>
        <SiteBody/>
    </>
    )
}