import {Site} from './components/main/Site'
import {Home as PlasticAndMetalComp} from "./components/plastic-metal-comps/Home"
import './App.css';
import {Routes, Route} from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/" element={<Site/>} exact/>
          <Route path="/plastic-and-metal-comp" element={<PlasticAndMetalComp/>} exact/>
      </Routes>
    </div>
  );
}

export default App;

