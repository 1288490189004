import React from "react"


const CompanyLogo = ({
    fill = "#EDE000",
    width = "841.41",
    height = "596.14", 
    x = ".34",
    y = "-1.28",
    viewBox = "0 0 841.89 595.28",
    // enableBackground = "new 0 0 841.89 595.28"

}) => (

<svg 
    style={{overflow:'visble'}} 
    width={width} height={height} 
    // enableBackground={enableBackground} 
    version="1.1" 
    viewBox={viewBox} 
    preserveAspectRatio="none" 
    xmlSpace="none" 
    xmlns="http://www.w3.org/2000/svg">
    <rect style={{fill: fill}} x={x} y={y} width="100%" height="100%"/>
    <path d="m478.08 183.84c0.79-4.46 2.36-6.83 4.46-6.83s3.67 1.31 7.09 8.14l71.67 148.06 71.41-149.9c2.1-4.2 3.41-6.3 5.77-6.3s3.94 2.63 4.73 8.14l23.89 162.24c2.36 16.54 4.99 24.15 12.86 26.52 7.61 2.36 12.86 2.62 16.28 2.62 2.36 0 4.2 0.26 4.2 1.84 0 2.1-3.15 2.89-6.83 2.89-6.56 0-42.79-0.79-53.29-1.58-6.04-0.52-7.61-1.31-7.61-2.89 0-1.31 1.05-2.1 2.89-2.89 1.57-0.52 2.36-3.94 1.31-11.29l-15.75-115.51h-1.05l-57.24 119.2c-6.04 12.34-7.35 14.7-9.71 14.7s-4.99-5.25-9.19-13.13c-6.3-12.08-27.04-52.24-30.19-59.59-2.36-5.51-18.11-38.59-27.3-58.28h-1.05l-13.65 104.48c-0.52 4.73-0.66 2.98-0.66 7.18 0 4.99 1.75 6.67 6.21 7.72 4.73 1.05 9.23 3.39 11.86 3.39 2.1 0 4.64 4.29 4.64 5.6 0 2.36-2.36 2.89-6.3 2.89-11.03 0-22.84-0.79-27.57-0.79-4.99 0-12.82-0.74-21.22-0.74-2.63 0-9.04-2.34-9.04-4.71 0-1.31 1.12 1.51 3.74 1.51 2.1 0 3.94 0 7.88-0.79 7.35-1.57 7.77-11.07 10.76-20.74l26-171.16z"/>
    <path d="m176.51 256.39c0-39.9 0-47.25-0.52-55.66-0.52-8.93-2.62-13.13-11.29-14.96-2.1-0.52-6.56-0.79-10.24-0.79-2.89 0-4.46-0.53-4.46-2.36 0-1.84 1.84-2.36 5.78-2.36 13.91 0 30.45 0.79 37.8 0.79 10.76 0 25.99-0.79 38.07-0.79 32.81 0 44.63 11.03 48.83 14.96 5.77 5.51 13.13 17.33 13.13 31.24 0 37.28-27.3 63.53-64.58 63.53-1.31 0-4.2 0-5.51-0.26s-3.15-0.53-3.15-2.36c0-2.1 1.84-2.89 7.35-2.89 14.7 0 35.18-16.54 35.18-43.32 0-8.66-0.79-26.25-15.49-39.38-9.45-8.66-20.21-10.24-26.25-10.24-3.94 0-7.87 0.26-10.24 1.05-1.31 0.52-2.1 2.1-2.1 4.99v105.01c0 24.15 0 44.89 1.31 56.18 0.79 7.35 2.36 13.13 10.24 14.18 3.67 0.52 9.45 1.05 13.39 1.05 2.89 0 4.2 0.79 4.2 2.1 0 1.84-2.1 2.63-4.99 2.63-17.33 0-33.86-0.79-40.69-0.79-6.04 0-22.58 0.79-33.08 0.79-3.41 0-5.25-0.79-5.25-2.63 0-1.31 1.05-2.1 4.2-2.1 3.94 0 7.09-0.52 9.45-1.05 5.25-1.05 6.56-6.83 7.61-14.44 1.31-11.03 1.31-31.77 1.31-55.92v-46.2z"/>
    <path style={{fill:'none', stroke:'#000000', strokeWidth:6}} d="m308.64 262.16c-11.03-14.7-13.13-25.47-13.39-37.28-0.26-20.74 18.11-48.3 63.79-48.3 12.86 0 24.68 2.62 31.77 5.51 4.46 1.57 4.46 3.67 4.46 6.83 0 8.66-2.1 29.67-2.89 33.6-0.52 3.94-1.57 4.46-3.15 4.46s-1.84-1.05-1.84-4.99c0-6.82-1.84-15.22-7.61-22.05-5.25-6.3-14.44-11.81-29.14-11.81-11.03 0-28.09 7.61-28.09 29.93 0 24.68 22.58 45.68 43.58 69.04 8.93 10.24 41.22 39.38 46.2 43.84 5.25-7.35 12.6-24.41 12.6-40.43 0-3.94-1.05-8.66-4.99-12.08-3.41-3.15-8.93-3.94-12.86-3.94-1.58 0-3.15-0.52-3.15-2.1 0-2.1 2.1-2.62 4.2-2.62 6.3 0 16.8 0.26 31.5 1.84 4.99 0.52 8.93 2.1 8.93 5.25 0 20.21-10.76 44.63-25.73 62.22 17.59 14.44 29.4 21.53 36.49 25.73 8.4 4.99 22.84 9.19 28.09 9.19 2.36 0 3.68 0.26 3.68 2.36 0 1.57-2.1 2.36-7.88 2.36h-20.21c-29.93 0-39.38-2.89-61.96-20.21-8.66 7.88-26.25 23.89-62.22 23.89-56.18 0-72.98-35.18-72.98-58.02 0.01-30.72 27.05-54.61 42.8-62.22zm82.17 87.95c-15.23-13.13-40.43-37.8-47.25-45.16s-23.63-26.25-28.09-32.81c-8.93 6.56-18.11 18.9-18.11 39.9 0 32.81 23.1 53.82 50.4 53.82 20.21 0 37.54-10.5 43.05-15.75z"/>
    <path style={{fill: fill}} d="m308.64 262.16c-11.03-14.7-13.13-25.47-13.39-37.28-0.26-20.74 18.11-48.3 63.79-48.3 12.86 0 24.68 2.62 31.77 5.51 4.46 1.57 4.46 3.67 4.46 6.83 0 8.66-2.1 29.67-2.89 33.6-0.52 3.94-1.57 4.46-3.15 4.46s-1.84-1.05-1.84-4.99c0-6.82-1.84-15.22-7.61-22.05-5.25-6.3-14.44-11.81-29.14-11.81-11.03 0-28.09 7.61-28.09 29.93 0 24.68 22.58 45.68 43.58 69.04 8.93 10.24 41.22 39.38 46.2 43.84 5.25-7.35 12.6-24.41 12.6-40.43 0-3.94-1.05-8.66-4.99-12.08-3.41-3.15-8.93-3.94-12.86-3.94-1.58 0-3.15-0.52-3.15-2.1 0-2.1 2.1-2.62 4.2-2.62 6.3 0 16.8 0.26 31.5 1.84 4.99 0.52 8.93 2.1 8.93 5.25 0 20.21-10.76 44.63-25.73 62.22 17.59 14.44 29.4 21.53 36.49 25.73 8.4 4.99 22.84 9.19 28.09 9.19 2.36 0 3.68 0.26 3.68 2.36 0 1.57-2.1 2.36-7.88 2.36h-20.21c-29.93 0-39.38-2.89-61.96-20.21-8.66 7.88-26.25 23.89-62.22 23.89-56.18 0-72.98-35.18-72.98-58.02 0.01-30.72 27.05-54.61 42.8-62.22zm82.17 87.95c-15.23-13.13-40.43-37.8-47.25-45.16s-23.63-26.25-28.09-32.81c-8.93 6.56-18.11 18.9-18.11 39.9 0 32.81 23.1 53.82 50.4 53.82 20.21 0 37.54-10.5 43.05-15.75z"/>
    <text style={{fontFamily:'AceSansExtrabold', fontSize:'31.6587px'}} transform="translate(451.81 427.3)">COMPONENTS</text>
</svg>

)

export default CompanyLogo;